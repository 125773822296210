import Layout from 'layouts'
import React, { lazy } from 'react'
import { Redirect, Route as ReactRouter, Switch } from 'react-router-dom'
import useAuth from 'store/AuthContext'

const AllCategoryPage = lazy(() => import('pages/AllCategoryPage'))
const BrandPage = lazy(() => import('pages/BrandPage'))
const CategoryPage = lazy(() => import('pages/CategoryPage'))
const Error404Page = lazy(() => import('pages/Error404Page'))
const InfoPage = lazy(() => import('pages/InfoPage'))
const Landing = lazy(() => import('pages/landing'))
const ProductPage = lazy(() => import('pages/Product/ProductPage'))
const PromotionsPage = lazy(() => import('pages/PromotionsPage'))
const SalesPage = lazy(() => import('pages/SalesPage'))
const SearchPage = lazy(() => import('pages/SearchPage'))
const SinglePost = lazy(() => import('pages/SinglePost'))
const AccountPage = lazy(() => import('pages/User/AccountPage'))
const SingleOrderPage = lazy(() => import('pages/User/SingleOrderPage'))
const PurchasePage = lazy(() => import('pages/PurchasePage'))
const HomePage = lazy(() => import('pages/HomePage'))
const CosmoAds = lazy(() => import('pages/CosmoAds'))
const FeedsPage = lazy(() => import('pages/FeedsPage'))

export const routes = [
  {
    path: '/',
    exact: true,
    render: ({ location }) => {
      const queryParams = new URLSearchParams(location.search)
      const id = queryParams.get('id')
      if (id) {
        return <CosmoAds /> // Render CosmoAds if id query param is present
      }
      return <HomePage /> // Render HomePage otherwise
    },
  },
  // {
  //   path: '/feed.php',
  //   component: FeedsPage,
  // },
  /* {
    path: '/about',
    component: About,
    layoutProps: {
      type: 'withoutContainer',
    },
  }, */
  {
    path: '/all-category',
    component: AllCategoryPage,
  },
  {
    path: '/purchase',
    component: PurchasePage,
  },
  {
    path: '/category/:slug?/:keyword?',
    component: CategoryPage,
  },
  {
    path: '/search/:keyword',
    component: SearchPage,
  },
  {
    path: '/brand/:slug',
    component: BrandPage,
  },
  {
    path: '/sale',
    component: SalesPage,
  },
  { path: '/page/usafrtxoebis-politika', component: InfoPage },
  { path: '/page/terms-and-conditions', component: InfoPage },
  { path: '/page/mitsodebis-pirobebi', component: InfoPage },
  { path: '/page/khshirad-dasmuli-kitkhvebi', component: InfoPage },
  { path: '/page/garantia-dabruneba-gadatsvla', component: InfoPage },
  { path: '/page/about-us', component: InfoPage },
  {
    path: '/page/:slug',
    component: InfoPage,
  },
  {
    path: '/landing/:signature',
    component: Landing,
    layoutProps: {
      layoutType: 'landing',
    },
  },
  {
    path: '/post/:slug',
    component: SinglePost,
  },
  {
    path: '/product/:slug/:path?',
    component: ProductPage,
  },
  {
    path: '/account/:param1/:param2?/:param3?',
    secured: true,
    component: AccountPage,
  },
  {
    path: '/order/:hash',
    // guest: true,
    component: SingleOrderPage,
  },
  {
    path: '/promotions/:slug?',
    component: PromotionsPage,
  },
  {
    path: '/*',
    component: Error404Page,
  },
]

export const renderRoutes = (routes, extraProps = {}, switchProps = {}) => {
  return (
    <Switch {...switchProps}>
      {routes.map((route, i) => {
        const render = props => {
          const LayoutComponent = route.layout || Layout
          const layoutProps = route.layoutProps || {}

          if (route.render) {
            return (
              <LayoutComponent {...layoutProps}>
                {route.render({ ...props, ...extraProps, route: route })}
              </LayoutComponent>
            )
          } else if (route.component) {
            return (
              <LayoutComponent {...layoutProps}>
                <route.component {...props} {...extraProps} route={route} />
              </LayoutComponent>
            )
          }
        }

        return (
          <Route
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            guest={route.guest}
            secured={route.secured}
            strict={route.strict}
            render={render}
          />
        )
      })}
    </Switch>
  )
}

export const Route = ({ secured, guest, ...props }) => {
  const { auth, loading } = useAuth()
  if (secured && !auth && !loading) return <Redirect to="/" />
  if (guest && auth && !loading) return <Redirect to="/" />

  return <ReactRouter {...props} />
}
