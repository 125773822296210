import { incrementOrder } from 'helpers/testIfCanSendOrder'
import api from './api'
import WebApi from './WebApi'

class OrdersService {
  static getOrders = () => {
    return api.call('post', `/order/by-user`).then(api.getData)
  }

  static getCosmoAds = id => {
    return WebApi.call('get', `/land/${id}`).then(WebApi.getData)
  }

  static getOrderByHash = hash => {
    return api.call('post', `/order/by-hash/${hash}`).then(api.getData)
  }

  static placeLandingOrder = formdata => {
    formdata.append('nj_session', window.nj?.getSession())
    formdata.append('nj_user', window.nj?.getUser())
    return api
      .call('post', `/order/place-landing-order`, formdata)
      .then(api.getData)
      .then(d => {
        incrementOrder()
        return d
      })
  }

  static placeCartOrder = formdata => {
    formdata.append('nj_session', window.nj?.getSession())
    formdata.append('nj_user', window.nj?.getUser())
    return api
      .call('post', `/order/place-cart-order`, formdata)
      .then(api.getData)
      .then(d => {
        incrementOrder()
        return d
      })
  }

  static updateAddress = formData => {
    return api.call('post', `/order/update-address`, formData).then(api.getData)
  }

  static updateDetails = formData => {
    return api.call('post', `/order/update-details`, formData).then(api.getData)
  }

  static approveDetails = formData => {
    return api
      .call('post', `/order/update-approved`, formData)
      .then(api.getData)
  }

  static calNeeded = formData => {
    return api.call('post', `/order/call-needed`, formData).then(api.getData)
  }

  static confirmAuthCode = formdata => {
    return api
      .call('post', `/order/confirm-auth-code`, formdata)
      .then(api.getData)
      .then(data => {
        if (data.token && data.success) {
          api.setToken(data.token)
        }
      })
  }
}

export default OrdersService
